@import url("https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=Caveat:wght@400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --navbar-bg-color: #fff;
  --primary-bg-color: #f0f2f5;
  --seconday-bg-color: #c2410c;
  --white: #fff;
  --brand-color: #1d3557;
  --cta-bg-color: #1d3557;
  --danger-bg-color: #e63946;
  --highligh-green: #12af2f;
  --yellow: #ffc30f;
  --navbar-height: 3.5rem;
  --mob-navbar-height: 5rem;
  --footer-height: 3rem;
  --page_bg-color: #fff7ed;
  --footer-bg-color: #18181b;
  --open-sans-font: "Open Sans", sans-serif;
  --montserrat-font: "Montserrat", sans-serif;
  --caveat-sans-font: "Caveat", cursive;
  --poppins-font: "Poppins", sans-serif;
  --abee-zee-font: "ABeeZee", sans-serif;
  --mob-screen-size: 673px;
}

body {
  font-family: var(--open-sans-font);
}

.bg-prinary-color {
  background-color: var(--page_bg-color);
}
