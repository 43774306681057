.blog-page-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cpp-page-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
