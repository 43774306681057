.home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.personal-details-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 4rem 1rem;
  background-color: var(--page_bg-color);

  .hero-image-wrapper {
    width: 100%;
    overflow: hidden;
    text-align: right;
    padding-right: 3rem;
    .hero-image {
      width: 250px;
    }
  }

  @media (max-width: 673px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    background-color: var(--page_bg-color);

    .hero-image-wrapper {
      width: 100%;
      overflow: hidden;
      text-align: center;
      padding: 2rem 0;
      .hero-image {
        width: 250px;
      }
    }
  }
}

.personal-details-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    font-family: var(--montserrat-font);
    font-size: 3rem;
    letter-spacing: 1px;

    span {
      color: var(--seconday-bg-color);
    }
  }

  h3 {
    width: 70%;
    font-family: var(--abee-zee-font);
    font-size: 1.5rem;
    letter-spacing: 1px;
    font-weight: 400;
    margin-top: 1rem;
  }

  .social-links-wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;

    a {
      margin-right: 1rem;
      text-decoration: none;
      color: black;
    }
  }

  @media (max-width: 673px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      text-align: center;
    }

    h3 {
      width: 70%;
      font-family: var(--abee-zee-font);
      font-size: 1.5rem;
      letter-spacing: 1px;
      font-weight: 400;
      margin-top: 1rem;
      text-align: center;
    }
  }
}

.skills-container {
  padding: 3rem 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  justify-items: center;

  @media (max-width: 700px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .grid-cell {
    width: 100%;
    padding: 1rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 80px;
    }

    .skills {
      margin-top: 1rem;
      letter-spacing: 1px;
      text-align: center;
      line-height: 1.5rem;
      font-family: var(--poppins-font);
    }
  }
}

.experience-container {
  width: 100%;
  padding: 5rem 0;
  background-color: var(--page_bg-color);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  justify-items: center;

  @media (max-width: 700px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .experience-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-family: var(--abee-zee-font);
      font-size: 1.7rem;
      letter-spacing: 1px;
    }

    .experience-info {
      padding: 1rem 0;
      width: 75%;
      letter-spacing: 1px;
      line-height: 1.5rem;
      text-align: left;
      margin-top: 1rem;
      font-family: var(--abee-zee-font);
    }

    .experience-points {
      width: 75%;
      letter-spacing: 1px;
      line-height: 1.5rem;
      font-family: var(--abee-zee-font);
    }
  }

  .image-wrapper {
    width: 300px;
    overflow: hidden;

    img {
      width: 300px;
      border-radius: 1rem;
    }
  }
}

.education-container {
  width: 100%;
  padding: 5rem 0;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  justify-items: center;

  @media (max-width: 700px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .education-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-family: var(--abee-zee-font);
      font-size: 1.7rem;
      letter-spacing: 1px;
    }

    .education-points {
      margin-top: 1rem;
      width: 75%;
      letter-spacing: 1px;
      line-height: 1.5rem;
      font-family: var(--abee-zee-font);
    }
  }

  .image-wrapper {
    width: 300px;
    overflow: hidden;

    img {
      width: 300px;
      border-radius: 1rem;
    }
  }
}
