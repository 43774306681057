.double-layout-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .left-layout {
    width: 100%;
  }
  .right-layout {
    width: 100%;
  }
}
