.header-container {
  width: 100%;
  min-height: var(--navbar-height);
  background-color: var(--page_bg-color);
  display: grid;
  grid-template-columns: 0.5fr 0.6fr;
  gap: 1rem;
  align-items: center;
  padding: 0.3rem 0;

  @media (max-width: 673px) {
    width: 100%;
    min-height: var(--mob-navbar-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
}

.brand-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;

    .brand-text {
      font-size: 2.3rem;
      font-family: var(--caveat-sans-font);
      letter-spacing: 1px;
      color: black;
    }
  }
}

.brand-image {
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
}

.navbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .nav-item {
    text-decoration: none;
    padding: 0 0.75rem;
    font-size: 1.2rem;
    font-family: var(--abee-zee-font);
    font-weight: 600;
    letter-spacing: 1px;
    color: gray;
  }

  .nav-item:hover {
    color: var(--seconday-bg-color);
  }

  .nav-item.active {
    color: var(--seconday-bg-color);
    font-weight: 900;
  }
}
