.app-center {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  font-family: var(--dancing-font);
  font-size: 1.5rem;
}

.app-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}
