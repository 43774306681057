.yt-item-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.yt-item-link {
  img {
    width: 100%;
  }
}
