.footer-container {
  width: 100%;
  max-height: var(--footer-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--footer-bg-color);
  padding: 1rem 0;

  .footer-copyrights {
    color: white;
    font-weight: 400;
    letter-spacing: 2px;
    text-align: center;
  }
}
